// App.js
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/Login';
import MainApp from './components/MainApp';
import CreateUser from './components/CreateUser';
import Clients from './components/Clients';
import Users from './components/Users';
import ClientValidation from './components/ClientValidation';
import UsersClient from './components/UsersClient';
import Start from './components/Start';
import LandingPage from './components/Landing';
import COMSInventory from './components/COMS.inventory';
import COMSCatalog from './components/COMS.catalog';
import COMSStores from './components/COMS.stores';
import COMSCategories from './components/COMS.categories';

function App() {
  return (
    <Routes>
      {/* Ruta para el login */}
      <Route path="/login" element={<Login />} />
      
      {/* Ruta para el MainApp después del inicio de sesión */}
      <Route path="/MainApp" element={<MainApp />} />
      <Route path="/CreateUser" element={<CreateUser />} />
      <Route path="/Clients" element={<Clients />} />
      <Route path="/Users" element={<Users />} />
      <Route path="/UsersClient" element={<UsersClient />} />

      <Route path="/ClientValidation" element={<ClientValidation />} />
      <Route path="/Start" element={<Start />} />
      <Route path="/Landing" element={<LandingPage />} />
      <Route path="/CoMS/Inventory" element={<COMSInventory />} />
      <Route path="/CoMS/Catalog" element={<COMSCatalog />} />
      <Route path="/CoMS/Stores" element={<COMSStores />} />
      <Route path="/CoMS/Categories" element={<COMSCategories />} />
      {/* Redirecciona cualquier ruta desconocida al login */}
      <Route path="*" element={<Navigate to="/Landing" />} />
    </Routes>
  );
}

export default App;
 