// src/AuthService.js

const  ipProxy ='167.88.45.176';
const  proxy ='PRXLB01';
class ClientService {
  async client(name,lastName,email,contact, password) {
    
  
      const usPass = btoa(password);

      const response = await fetch('https://srv687349.hstgr.cloud/waf1/v1/access/post-client', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
         
          'X-PATH': '/core/clients/post',
          'X-APIKEY': sessionStorage.getItem('X-API-KEY'),
          'X-FRONT': sessionStorage.getItem('frontId'),
          'userId': sessionStorage.getItem('userId'),
          'sessionId': sessionStorage.getItem('sessionId')
        },
        body: JSON.stringify({
          name: name,
          lastName: lastName,
          email: email,
          contact: contact,

          pass: usPass,
        }),
      });

      const result = await response.json();
      if(result.validated===false){
        return result;
      }
      sessionStorage.setItem('X-API-KEY', result.data[0].token);
      sessionStorage.setItem('userName', result.data[0].userName);
      sessionStorage.setItem('name', result.data[0].name);
      sessionStorage.setItem('lastName', result.data[0].lastName);
      sessionStorage.setItem('email', result.data[0].email);
      sessionStorage.setItem('contact', result.data[0].contact);
      sessionStorage.setItem('userId', result.data[0].userId);
      sessionStorage.setItem('sessionId', result.data[0].sessionId);
      sessionStorage.setItem('frontId', result.data[0].frontId);
      return result;
  
  }
  async clientGet(filter,param,value,limit, offset) {
    const requestData = {
      filter,
      param,
      value,
      limit,
      offset,
    };

    try {
        const response = await fetch('https://srv687349.hstgr.cloud/waf1/v1/access/get-clients?filter='+filter+'&param='+param+'&value='+value+'&limit='+limit+'&offset='+offset, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-APIKEY': sessionStorage.getItem('X-API-KEY'),
          'X-PATH': '/core/clients/get/',
          'X-FRONT': sessionStorage.getItem('frontId'),
          'userId': sessionStorage.getItem('userId'),
          'sessionId': sessionStorage.getItem('sessionId')
        },
      });

     // const result = await response.json();
    //  setResponseMessage(result.message || 'Datos recibidos correctamente.');

      // Agregamos un console.log para inspeccionar la respuesta completa
     // console.log('Respuesta completa del API:', response);

        return response;
      
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      //setResponseMessage('Error al hacer la solicitud.');
    }
  
  }

}

export default new ClientService();
