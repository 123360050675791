import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const Footer = ({ currentPage, totalPages, onPageChange }) => {
  
  const [horaInicioSesion, setHoraInicioSesion] = useState('');
  const [horaExpiracion, setHoraExpiracion] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
 
  
  }, []); // Solo se ejecuta una vez al montar el componente

    return (
      <footer class="footer">
  <div class="content has-text-centered">
    <p>
      <strong>© 2025</strong>
       </p>
   
      <strong>by</strong><a href="https://jgthms.com"> Lugma</a>
      <p>
      <img
            
              src="https://dev-toolix-admin.lugma.digital/LUGMALOGO.png"
              alt="Logo"
              style={{ width: '3%', height: '3%' }}

            />
            </p>
     
    
  </div>
</footer>
    );
  };

const styles = {
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
  },
  button: {
    padding: '10px 20px',
    margin: '0 5px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  pageInfo: {
    fontSize: '16px',
    margin: '0 10px',
  },
};

export default Footer;
