import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserServiceClient from '../classes/UserServiceClient';
import AuthService from '../classes/AuthService';
import ClientService from '../classes/ClientService';

import Header from './Header';
import Pagination from './Pagination';
import Swal from 'sweetalert2';
import Footer from './Footer';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee, faEye, faTrash,faCheck,faXmark, faUser, faUpLong, faGear, faPlus } from "@fortawesome/free-solid-svg-icons";
  function UsersClient() {
  const navigate = useNavigate();
 
  useEffect(() => {
    if(!sessionStorage.getItem('userId') || !sessionStorage.getItem('frontId') || !sessionStorage.getItem('sessionId') || !sessionStorage.getItem('X-API-KEY')){
      navigate('/Login');
    }
    handlePageChangeF(1,0);
    console.log('MainApp component is being rendered');
   handlegetclients();
  }, []);
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientKey, setClientKey] = useState('');
  const [clientContact, setClientContact] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [clients, setClients] = useState([]);
  //var totalPages = 2; // Número total de páginas
  const [totalPages, setCTotalPages] = useState(2);
  // Estados para el formulario de creación de cliente
 
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
 
  // Estados para la solicitud GET
  const [filter, setFilter] = useState('');
  const [param, setParam] = useState('');
  const [value, setValue] = useState('');
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [clientsData, setClientsData] = useState([]);


  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [contact, setContact] = useState('');
  const [client, setClient] = useState('');
  const [clientNAME, setClientNAME] = useState('');

  // Función para manejar el envío del formulario de creación de cliente
  var registersqty=0;
  var paginationMessage="";
  
  async function handlegetclients  () {
    

    const response =  await ClientService.clientGet('all', 'all','all',100,0);
  
     
    const result =  await response.json();
    //sessionStorage.setItem('clientsNow',JSON.stringify(result));
  
    const clientsData = JSON.parse(JSON.stringify(result));

    if (clientsData && clientsData.data) {
      // Si los datos están disponibles, obtenemos el array de clientes con id y name
      const clientList = clientsData.data.map(client => ({
        id: client.id,        // id del cliente
        name: client.name     // nombre del cliente
      }));
      setClients(clientList);  // Guardamos la lista de clientes en el estado
    }
  };
  const handleClientSubmit = async (e) => {
    e.preventDefault();
var pass=password;
    const data = {
      name,
      lastName,
      email,
      contact,
      pass:btoa(pass),
      clientId:client,
      clientName:clientNAME,
      qId: '14574547'
    };

    try {
      const  ipProxy ='167.88.45.176';
      const  proxy ='PRXLB01';
      const response = await fetch('https://srv687349.hstgr.cloud/waf1/v1/access/post-user-client', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
         
          'X-PATH': '/core/usersclient/post',
          'X-APIKEY': sessionStorage.getItem('X-API-KEY'),
          'X-FRONT': sessionStorage.getItem('frontId'),
          'userId': sessionStorage.getItem('userId'),
          'sessionId': sessionStorage.getItem('sessionId')
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){
         
      return AuthService.sessionExpired();
    }
    } catch (error) {
      console.error('Error en el envío:', error);
    }
  };
  // Función para manejar el envío de la solicitud GET
  const handleGetSubmit = async (e) => {
    e.preventDefault();
     const requestData = {
      filter,
      param,
      value,
      limit,
      offset,
    };

    try {
      const response = await UserServiceClient.userGet(filter, param,value,limit,offset);
  
     

      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated===false){

        await AuthService.logout();
 
    
        
 console.log('validado');
       return  navigate('/Login');
      
       
   }
      setResponseMessage(result.message || 'Datos recibidos correctamente.');

      // Agregamos un console.log para inspeccionar la respuesta completa
      console.log('Respuesta completa del API:', result);

      // Ahora accedemos a destination_response.data para obtener los datos de clientes
      if (result?.data) {
        setClientsData(result.data);
      } else {
        setClientsData([]); // Si no hay datos o el formato no es el esperado
        console.log('No se encontraron datos de clientes o el formato no es el esperado');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
  };
  const handlePageChange = async (page,offset) => {
    setCurrentPage(page);
    page = Number(page);
    offset = 0;
  
    if (page > 1) {
      offset = (page - 1) * 10;
    }
    if(filter==""){
      setFilter('all');
    }
    if(param==""){
      setParam('all');
    }
    if(value==""){
      setValue('all');
    }
    console.log(`Cambió a la página: ${page}`);
    const requestData = {
      filter,
      param,
      value,
      limit: 10,
      offset,
    };
  
    try {
     
   
      const response = await UserServiceClient.userGet(filter, param,value,limit,offset);
  
     

      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){

        await AuthService.logout();
 
    
        
 console.log('validado');
       return  navigate('/Login');
      
       
   }
      console.log('Respuesta completa del API:', result);
      paginationMessage=result.message;
      setCTotalPages(Math.ceil(paginationMessage / 10));
      console.log(totalPages);
      // Actualiza los datos y el mensaje de respuesta
      setClientsData(result.data || []);
      setResponseMessage(result.message || 'Datos recibidos correctamente.');
   
      // Ahora accedemos a destination_response.data para obtener los datos de clientes
      if (result?.data) {
        setClientsData(result.data);
      } else {
        setClientsData([]); // Si no hay datos o el formato no es el esperado
        console.log('No se encontraron datos de clientes o el formato no es el esperado');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
   
   
   
    
  };
  async function handlePageChangeF(page,offset){
    setCurrentPage(page);
    page = Number(page);
    offset = 0;
  
    if (page > 1) {
      offset = (page - 1) * 10;
    }
   
      setFilter('all');
   
   
      setParam('all');
  
   
      setValue('all');
    
    console.log(`Cambió a la página: ${page}`);
    const requestData = {
      filter,
      param,
      value,
      limit: 10,
      offset,
    };
  
    try {
     
   
      const response = await UserServiceClient.userGet('all', 'all','all',10,0);
  
     

      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){

        await AuthService.logout();
 
    
        
 console.log('validado');
       return  navigate('/Login');
      
       
   }
      console.log('Respuesta completa del API:', result);
      paginationMessage=result.message;
      setCTotalPages(Math.ceil(paginationMessage / 10));
      console.log(totalPages);
      // Actualiza los datos y el mensaje de respuesta
      setClientsData(result.data || []);
      setResponseMessage(result.message || 'Datos recibidos correctamente.');
   
      // Ahora accedemos a destination_response.data para obtener los datos de clientes
      if (result?.data) {
        setClientsData(result.data);
      } else {
        setClientsData([]); // Si no hay datos o el formato no es el esperado
        console.log('No se encontraron datos de clientes o el formato no es el esperado');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
   
   
   
    
  };
  
  const handleClientChange = (e) => {
    const selectedClientId = e.target.value;
    setClient(selectedClientId);  // Actualizamos el id del cliente

    // Encontrar el cliente con el id seleccionado y actualizar el nombre
    const selectedClient = clients.find(client => client.id === selectedClientId);
    if (selectedClient) {
      setClientNAME(selectedClient.name);  // Actualizamos el nombre del cliente
    }
  };
const [isCollapsed, setIsCollapsed] = useState(true);
     const toggleCollapse = () => {
      setIsCollapsed(!isCollapsed);
    };
  
    const [isCollapsedCr, setIsCollapsedCr] = useState(true);
    const toggleCollapseCr = () => {
     setIsCollapsedCr(!isCollapsedCr);
   };
  return (
   
    <div className="App"  class="box">
     
       {/* Navbar de Bulma */}
       <Header
        currentPage={1}
        totalPages={1}
        onPageChange={1}
      />

<h1 style={styles.heading}>External Users</h1>
 <div className="card">
        <header className="card-header" style={styles.header} onClick={toggleCollapse}>
          <p className="card-header-title"><FontAwesomeIcon icon={faGear} size="1x" /> Search and Edit</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapse}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsed ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsed && (
          <div className="card-content">
            
            <div className="content">
              
      <form onSubmit={handleGetSubmit}  style={styles.form} class="box">
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Filtro:</label>
          <input
           class="input  is-small is-rounded"
           type="text"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Parámetro:</label>
          <input
           class="input  is-small is-rounded"
           type="text"
            value={param}
            onChange={(e) => setParam(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Valor:</label>
          <input
           class="input  is-small is-rounded"
           type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
       
       
        <button type="submit"  class="button is-rounded is-small">Buscar</button>
      </form>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      {clientsData.length > 0 && (
        <table  class="table" style={styles.form}>
          <thead>
            <tr>
            <th >Lock/Active</th>
              <th >Alias</th>
              <th >ID</th>
              <th >Nombre</th>
              <th >Email</th>
              <th >Contacto</th>
              <th >Cliente</th>
              <th >Actions</th>
            </tr>
          </thead>
          <tbody>
  {clientsData.map((client) => (
   registersqty=registersqty+1,
    <tr key={client.userId}>
                <td >{client.info.status=== true ? <i class="tag is-rounded is-normal is-success fas" title='DETAILS'><FontAwesomeIcon icon={faCheck} size="1x" /></i>:<i class="tag is-rounded is-normal is-danger fas" title='DETAILS'><FontAwesomeIcon icon={faXmark} size="1x" /></i>}  {client.info.isActive=== true ? <i class="tag is-rounded is-normal is-success fas" title='DETAILS'><FontAwesomeIcon icon={faCheck} size="1x" /></i>:<i class="tag is-rounded is-normal is-warning fas" title='DETAILS'><FontAwesomeIcon icon={faXmark} size="1x" /></i>}</td>

                <td >{client.alias}</td>
                <td >{client.userId}</td>
                <td >{client.name} {client.lastName}</td>
                
                <td >{client.email}</td>
                <td >{client.contact}</td>
                <td >{client.info.clientId} / {client.info.clientName}</td>
                <td ><button class="button is-rounded fas fa-eye is-small" title='DETAILS' onClick={() => openMOdal({name:client.name,lastName:client.lastName,mail:client.email,contact:client.contact,id:client.userId}, client.info)}><FontAwesomeIcon icon={faEye} size="1x" /></button><button class="button is-rounded fas fa-trash is-small" title='ERASE' onClick={() => openMOdalRm({name:client.name,lastName:client.lastName,mail:client.email,contact:client.contact,id:client.userId}, client.info)}><FontAwesomeIcon icon={faTrash} size="1x" /></button></td>
   
    </tr>
    
  ))}
</tbody>
        </table>
      )}
       <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
             
            </div>
          </div>
        )}
      </div>


      <div className="card">
        <header className="card-header" style={styles.header} onClick={toggleCollapseCr}>
          <p className="card-header-title"><FontAwesomeIcon icon={faPlus} size="1x" /> Create</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseCr}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedCr ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedCr && (
          <div className="card-content">
            
            <div className="content">
              

     
     <form onSubmit={handleClientSubmit} style={styles.form}>
       <div style={styles.inputGroup}>
         <label class="tag is-normal is-rounded">Name:</label>
         <input
           type="text"
           value={name}
           onChange={(e) => setName(e.target.value)}
           class="input  is-small is-rounded"
         />
       </div>
       <div style={styles.inputGroup}>
         <label class="tag is-normal is-rounded">Last Name:</label>
         <input
           type="text"
           value={lastName}
           onChange={(e) => setLastName(e.target.value)}
           class="input  is-small is-rounded"
         />
       </div>
       <div style={styles.inputGroup}>
         <label class="tag is-normal is-rounded">E-Mail:</label>
         <input
           type="email"
           value={email}
           onChange={(e) => setEmail(e.target.value)}
           class="input  is-small is-rounded"
         />
       </div>
       <div style={styles.inputGroup}>
         <label class="tag is-normal is-rounded">Contact:</label>
         <input
           type="text"
           value={contact}
           onChange={(e) => setContact(e.target.value)}
           class="input  is-small is-rounded"
         />
       </div>
       <div style={styles.inputGroup}>
         <label class="tag is-normal is-rounded">Contraseña:</label>
         <input
           type="password"
           value={password}
           onChange={(e) => setPassword(e.target.value)}
           class="input  is-small is-rounded"
         />
       </div>
       <div style={styles.inputGroup}>
     <label class="tag is-normal is-rounded">Cliente:</label>
     <div className="select is-rounded is-small"> {/* Usamos la clase Bulma correctamente */}
       <select 
         id="selectClients" 
         onChange={handleClientChange}  // Llamamos a la función handleClientChange al cambiar el valor
         value={client}  // Vinculamos el select al estado 'client'
       >
         {/* Iteramos sobre la lista de clientes y creamos las opciones */}
         {clients.map((client, index) => (
           <option key={index} value={client.id}>
             {client.name} {/* El nombre es mostrado como texto */}
           </option>
         ))}
       </select>
     </div>

     {/* Aquí mostramos el nombre del cliente seleccionado */}
     {clientNAME && <p>Cliente Seleccionado: {clientNAME}</p>}
   </div>
       <button type="submit" class="button is-small is-rounded">Crear</button>
     </form>
             
            </div>
          </div>
        )}
      </div>
      
      


     <Footer
       
      />
    </div>
    
  );
}

const openMOdal= (basicData,data)=>{
  Swal.fire({
    title: "<strong>Información de Usuario</strong>",
    icon: "info",
    html: `
    ${data.params.credentials.userName}
    <label class="tag">Name</label>
    <input class="input is-rounded" value=${basicData.name.trim()} id="userNameInput"></input>
     <label class="tag">Last Name</label>
    <input class="input is-rounded" value=${basicData.lastName.trim()} id="userLastNameInput"></input>
     <label class="tag">E-Mail</label>
    <input class="input is-rounded" value=${basicData.mail} id="userEmailInput"></input>
     <label class="tag">Contact</label>
       <input class="input is-rounded" value=${basicData.contact.trim()} id="userContactInput"></input>
        <label class="tag">Rol</label>
       <input class="input is-rounded" value=${data.rol.type}></input>
      ${data.isActive===true ? 'ACTIVO':'INACTIVO'}
    `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: `
     Actualizar
    `,
    cancelButtonText: `
     Cancelar
    `,
     preConfirm: async () => {
                               
      async function excexOrder() {
        try {
          const response = await fetch('https://srv687349.hstgr.cloud/waf1/v1/access/put-user-client', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-APIKEY': sessionStorage.getItem('X-API-KEY'),
              'userId': sessionStorage.getItem('userId'),
              'sessionId': sessionStorage.getItem('sessionId'),
              'X-FRONT': sessionStorage.getItem('frontId'),
              'X-PATH': '/core/usersclient/put'
            },
            body: JSON.stringify({
              name: document.getElementById('userNameInput').value,
              lastName: document.getElementById('userLastNameInput').value,
              email: document.getElementById('userEmailInput').value,
              contact: document.getElementById('userContactInput').value,

              userId:basicData.id
            })
          });
    
          const result = await response.json();
          if(result.validated===false){
            return result;
          }
        //  sessionStorage.clear();
         
          console.log('validado');
               // return  window.location.href = 'Login';
    
      } catch (error) {
          console.error('Error en la ejecución de funciones:', error);
      }
  
      }
   return excexOrder();

}
  });
}

const openMOdalRm= (basicData,data)=>{
  Swal.fire({
    title: "<strong>Se eliminarà informaciòn del usuario</strong>",
    icon: "warning",
    html: `
    `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: `
      Aceptar
    `,
    cancelButtonText: `Cancelar
    `, preConfirm: async () => {
                               
      async function excexOrder() {
        try {
          const response = await fetch('https://srv687349.hstgr.cloud/waf1/v1/access/remove-user-client', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-APIKEY': sessionStorage.getItem('X-API-KEY'),
              'userId': sessionStorage.getItem('userId'),
              'sessionId': sessionStorage.getItem('sessionId'),
              'X-FRONT': sessionStorage.getItem('frontId'),
              'X-PATH': '/core/usersclient/remove'
            },
            body: JSON.stringify({
              userId:basicData.id
            })
          });
    
          const result = await response.json();
          if(result.validated===false){
            return result;
          }
        //  sessionStorage.clear();
         
          console.log('validado');
               // return  window.location.href = 'Login';
    
      } catch (error) {
          console.error('Error en la ejecución de funciones:', error);
      }
  
      }
   return excexOrder();

}
  });
}
const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#f4f7fc',
    color: '#333',
  },
  heading: {
    color: '#3f51b5',
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center',
  },
  form: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontSize: '16px',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  },
  responseMessage: {
    textAlign: 'center',
    color: '#4caf50',
    fontSize: '18px',
    marginTop: '20px',
  },
  table: {
    marginTop: '30px',
    width: '100%',
    borderCollapse: 'collapse',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  tableHeader: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px',
    textAlign: 'left',
  },
  tableData: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  }
};

export default UsersClient;
