
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../classes/AuthService';


function Start() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
 
  useEffect(() => {
    const response =  fetch('https://167.88.45.176/PRXGTW01/health');
    
    // Verifica si la respuesta fue exitosa (status 200)
    if (response.ok) {
      const data =  response.json(); // Asume que la respuesta es un JSON
      console.log('Health Status para el GATEWAY: ', data);
    } else {
      console.error('Error al obtener el estado de salud. Código de estado:', response.status);
    }

 
    const response1 =  fetch('https://167.88.45.176/PRXLB02/health');
    
    // Verifica si la respuesta fue exitosa (status 200)
    if (response1.ok) {
      const data =  response1.json(); // Asume que la respuesta es un JSON
      console.log('Health Status para el LOADBALANCER: ', data);
    } else {
      console.error('Error al obtener el estado de salud. Código de estado:', response.status);
    }

  }, []);
  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await AuthService.login(username, password);

      if (result.validated === true) {
       

        navigate('/MainApp');
      } else {
        setErrorMessage(result.response);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
  const handleCreateUser = async (e) => {
    e.preventDefault();
    try {
     

        navigate('/CreateUser');
      
    } catch (error) {
      setErrorMessage(error.message);
    }
  };
  return (
    <div style={styles.app}>
      <h1 style={styles.heading}>Iniciar Sesión Como cliente</h1>
      <form onSubmit={handleLoginSubmit} style={styles.form}>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Usuario:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            style={styles.input}
          />
        </div>
        <div style={styles.inputGroup}>
          <label style={styles.label}>Contraseña:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={styles.input}
          />
        </div>
        <button type="submit" style={styles.button}>Ingresar</button>
        <button onClick={handleCreateUser} style={styles.button}>Crear Cuenta</button>
      </form>
      {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
}
const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#f4f7fc',
    color: '#333',
  },
  heading: {
    color: '#3f51b5',
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center',
  },
  form: {
    maxWidth: '600px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontSize: '16px',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    marginTop: '10px',
  },
};
export default Start;
