import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import Header from './Header';
import ClientService from '../classes/ClientService';
import COMSService from '../classes/COMSService';
import AuthService from '../classes/AuthService';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faEye, faPen, faPlus, faTrash,faXmark } from "@fortawesome/free-solid-svg-icons";
import Footer from './Footer';

import { faArrowDownAZ, faBox, faBoxes, faBusinessTime, faCalendar, faCalendarCheck, faCalendarDay, faCalendarDays, faCar, faChain, faChartBar, faClose, faCoins, faDiagramNext, faDriversLicense, faExchange, faFile, faFileLines, faGear, faHome, faInbox, faList, faMoneyBill, faNetworkWired, faPeopleArrows, faPerson, faPersonMilitaryPointing, faPlaceOfWorship, faRadio, faRegistered, faServer, faShop, faSignOut, faSitemap, faStar, faStore, faUpLong, faUser, faUserGear, faUsers } from '@fortawesome/free-solid-svg-icons';
function COMSInventory() {
  const navigate = useNavigate();
 
  useEffect(() => {
    if(!sessionStorage.getItem('userId') || !sessionStorage.getItem('frontId') || !sessionStorage.getItem('sessionId') || !sessionStorage.getItem('X-API-KEY')){
      navigate('/Login');
    }
    
    handlePageChangeF(1,0);
  
    handlegetclients();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  var [registId, setRegistId] = useState(0);
  //var totalPages = 2; // Número total de páginas
  const [totalPages, setCTotalPages] = useState(2);
  // Estados para el formulario de creación de cliente
  const [productName, setproductName] = useState('');
  const [description, setdescription] = useState('');
  const [sku, setsku] = useState('');
  const [isSkuOwn, setisSkuOwn] = useState('');
  const [ean1, setean1] = useState('');

  const [ean2, setean2] = useState('');

  const [imageUrl, setimageUrl] = useState('');
  const [stock, setstock] = useState('');

  const [securityStock, setsecurityStock] = useState('');
  const [basePrice, setbasePrice] = useState('');

  const [isWeighable, setisWeighable] = useState({
    "isActive": false,
    "unitWeight": "",
    "weight": 0,
    "pricePerWeight": 0.0
  },
);
  const [isUnitBased, setisUnitBased] = useState({
    "isActive": false,
    "quantity": 123,
    "pricePerUnit": 12.050
  });
  const [isPackageBased, setisPackageBased] = useState({
    "isActive": false,
    "qtyPerPackage": 0,
    "pricePerUnit": 0.0,
    "pricePerPackage": 0.0,
    "isDiscountable": false
  });

  const [isVolumeBased, setisVolumeBased] = useState({
    "isActive": false,
    "unitVolume": "",
    "volume": 0.0,
    "pricePerVolume": 0.0
  });

  const [isBulk, setisBulk] = useState({
    "isActive": false,
    "unitWeightOrVolume": "",
    "bulkPrice": 0.0
  });

  const [isServiceBased, setisServiceBased] = useState({
    "isActive": false,
    "duration": 0,
    "pricePerService": 0.0
  });








  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
 
  // Estados para la solicitud GET
  const [filter, setFilter] = useState('');
  const [param, setParam] = useState('');
  const [value, setValue] = useState('');
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [clientsData, setClientsData] = useState([]);


   const [isCollapsed, setIsCollapsed] = useState(true);
   const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const [isCollapsedWeighable, setisCollapsedWeighable] = useState(true);
  const toggleCollapseisCollapsedWeighable = () => {
   setisCollapsedWeighable(!isCollapsedWeighable);
 };

  const [isCollapsedCr, setIsCollapsedCr] = useState(true);
  const toggleCollapseCr = () => {
   setIsCollapsedCr(!isCollapsedCr);
 };
var registersqty=0;
var paginationMessage="";

  // Función para manejar el envío del formulario de creación de cliente
  const handleClientSubmit = async (e) => {
    e.preventDefault();

    const data = {
      clientId:client,
      productName,
      description,
      sku,
      isSkuOwn,
      isSkuOwn,

      ean1,

      ean2,

      imageUrl,
      stock,

      securityStock,

      basePrice,
      isWeighable,
      isUnitBased,

      isPackageBased,

      isVolumeBased,
      isBulk,

      isServiceBased,





      qId: '14574547',
    };

    try {
      const  ipProxy ='167.88.45.176';
      const  proxy ='PRXLB01';
      const response = await fetch('https://srv687349.hstgr.cloud/waf1/v1/accessops/post-products', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
         
          'X-PATH': '/core/clients/post',
          'X-APIKEY': sessionStorage.getItem('X-API-KEY'),
          'X-FRONT': sessionStorage.getItem('frontId'),
          'userId': sessionStorage.getItem('userId'),
          'sessionId': sessionStorage.getItem('sessionId')
        },
        body: JSON.stringify(data),
      });
      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){
         
      return AuthService.sessionExpired();
    }
    } catch (error) {
      console.error('Error en el envío:', error);
    }
  };

  // Función para manejar el envío de la solicitud GET
  const handleGetSubmit = async (e, limit, offset) => {
    if (e && e.preventDefault) {
      e.preventDefault(); // Evitar el comportamiento predeterminado del formulario
    }
 
  
  
    try {
      
      const response = await COMSService.productsGet(filter, param,value,10,0);
  
     
      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){
        return  AuthService.sessionExpired();

    }
      console.log('Respuesta completa del API:', result);
      paginationMessage=result.message;
      
      setResponseMessage(result.message || 'Datos recibidos correctamente.');
      setClientsData(result.data);
     
      
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
  };
  
  const handlePageChange = async (page,offset) => {
    setCurrentPage(page);
    page = Number(page);
    offset = 0;
  
    if (page > 1) {
      offset = (page - 1) * 10;
    }
    if(filter==""){
      setFilter('all');
    }
    if(param==""){
      setParam('all');
    }
    if(value==""){
      setValue('all');
    }
    console.log(`Cambió a la página: ${page}`);
    const requestData = {
      filter,
      param,
      value,
      limit: 10,
      offset,
    };
  
    try {
     
   
      const response = await COMSService.productsGet(filter, param,value,limit,offset);
  
     

      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){
        return  AuthService.sessionExpired();

        
    }
      console.log('Respuesta completa del API:', result);
      paginationMessage=result.message;
      setCTotalPages(Math.ceil(paginationMessage / 10));
      console.log(totalPages);
      // Actualiza los datos y el mensaje de respuesta
      setClientsData(result.data || []);
      setResponseMessage(result.message || 'Datos recibidos correctamente.');
   
      // Ahora accedemos a destination_response.data para obtener los datos de clientes
      if (result?.data) {
        setClientsData(result.data);
      } else {
        setClientsData([]); // Si no hay datos o el formato no es el esperado
        console.log('No se encontraron datos de clientes o el formato no es el esperado');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
   
   
   
    
  };
  

  async function handlePageChangeF(page,offset)  {
    setCurrentPage(page);
    page = Number(page);
    offset = 0;
  
    if (page > 1) {
      offset = (page - 1) * 10;
    }
    setFilter('all');
   
   
    setParam('all');

 
    setValue('all');
    console.log(`Cambió a la página: ${page}`);
    const requestData = {
      filter,
      param,
      value,
      limit: 10,
      offset,
    };
  
    try {
     
   
      const response = await COMSService.productsGet('all', 'all','all',10,0);
  
     

      const result = await response.json();
      if(result.response=="IAM ISSUE" && result.validated==false){
        return  AuthService.sessionExpired();

        
    }

      console.log('Respuesta completa del API:', result);
      paginationMessage=result.message;
      setCTotalPages(Math.ceil(paginationMessage / 10));
      console.log(totalPages);
      // Actualiza los datos y el mensaje de respuesta
      setClientsData(result.data || []);
      setResponseMessage(result.message || 'Datos recibidos correctamente.');
   
      // Ahora accedemos a destination_response.data para obtener los datos de clientes
      if (result?.data) {
        setClientsData(result.data);
      } else {
        setClientsData([]); // Si no hay datos o el formato no es el esperado
        console.log('No se encontraron datos de clientes o el formato no es el esperado');
      }
    } catch (error) {
      console.error('Error al hacer la solicitud:', error);
      setResponseMessage('Error al hacer la solicitud.');
    }
   
   
   
    
  };
    const [clients, setClients] = useState([]);
  
   const [client, setClient] = useState('');
    const [clientNAME, setClientNAME] = useState('');
  
  const handleClientChange = (e) => {
    const selectedClientId = e.target.value;
    setClient(selectedClientId);  // Actualizamos el id del cliente

    // Encontrar el cliente con el id seleccionado y actualizar el nombre
    const selectedClient = clients.find(client => client.id === selectedClientId);
    if (selectedClient) {
      setClientNAME(selectedClient.name);  // Actualizamos el nombre del cliente
    }
  };
   async function handlegetclients  () {
      
  
      const response =  await ClientService.clientGet('all', 'all','all',100,0);
    
       
      const result =  await response.json();
      //sessionStorage.setItem('clientsNow',JSON.stringify(result));
    
      const clientsData = JSON.parse(JSON.stringify(result));
  
      if (clientsData && clientsData.data) {
        // Si los datos están disponibles, obtenemos el array de clientes con id y name
        const clientList = clientsData.data.map(client => ({
          id: client.id,        // id del cliente
          name: client.name     // nombre del cliente
        }));
        setClients(clientList);  // Guardamos la lista de clientes en el estado
      }
    };

    const handleTypeChangeWeighable = (event) => {
      const value = event.target.value;
  
      if (value === "true") {
        // Actualiza todos los estados para que 'isActive' sea false
        setisUnitBased((prev) => ({ ...prev, isActive: false }));
        setisPackageBased((prev) => ({ ...prev, isActive: false }));
        setisVolumeBased((prev) => ({ ...prev, isActive: false }));
        setisBulk((prev) => ({ ...prev, isActive: false }));
        setisServiceBased((prev) => ({ ...prev, isActive: false }));

        console.log('changed:',value,isWeighable.isActive);
      }
    };
    const handleTypeChangeUnitBases = (event) => {
      const value = event.target.value;
  
      if (value === "true") {
        // Actualiza todos los estados para que 'isActive' sea false
        setisWeighable((prev) => ({ ...prev, isActive: false }));
        setisPackageBased((prev) => ({ ...prev, isActive: false }));
        setisVolumeBased((prev) => ({ ...prev, isActive: false }));
        setisBulk((prev) => ({ ...prev, isActive: false }));
        setisServiceBased((prev) => ({ ...prev, isActive: false }));

        console.log('changed:',value,isWeighable.isActive);
      }
    };
  return (
    
    <div className="App"  class="box">
       {/* Navbar de Bulma */}
       <Header
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />


<h1 style={styles.heading}>CoMS - Inventory</h1>

<div className="card">
        <header className="card-header" style={styles.header} onClick={toggleCollapse}>
          <p className="card-header-title"><FontAwesomeIcon icon={faGear} size="1x" /> Search and Edit</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapse}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsed ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsed && (
          <div className="card-content">
           
            <div className="content">
              <div className="media columns is-multiline">
                <div className="box media-content " >
                  

      
      <form onSubmit={(e) => handleGetSubmit(e, 5, 0)} class="box" style={styles.form}>

        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Filtro:</label>
          <input
            class="input  is-small is-rounded"
            type="text"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Parámetro:</label>
          <input
            class="input  is-small is-rounded"
            type="text"
            value={param}
            onChange={(e) => setParam(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Valor:</label>
          <input
            class="input  is-small is-rounded"
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
       
       
        <button type="submit" class="button is-rounded is-small">Buscar</button>
      </form>
      <h2 style={styles.responseMessage}>{responseMessage} Clients found</h2>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
      {clientsData.length > 0 && (
        <table class="table" style={styles.form}>
          <thead>
            <tr>
            <th >Iamge</th>
            <th >Name</th>
            <th >Description</th>
           
              <th >Sku</th>
              <th >EAN1 / EAN2</th>
              <th >Stock</th>
              <th >Sec-Stock</th>
              <th >Price</th>
              <th >Type</th>
            </tr>
          </thead>
          <tbody>
  {clientsData.map((client) => (
   registersqty=registersqty+1,
    <tr key={client.productId}>
      
        <td style={styles.tableData}> <img
           
              src={client.imageUrl}
              alt="Icon"
              style={{ width: '100%', height: '100%' }}
            /></td>
        
     
      <td style={styles.tableData}>{client.productName || 'N/A'}</td>
      <td style={styles.tableData}>{client.id || 'N/A'}</td>
      <td style={styles.tableData}>{client.description || 'N/A'}</td>
      <td style={styles.tableData}>{client.sku || 'N/A'}</td>
      
      <td style={styles.tableData}>{client.ean1 || 'N/A'} / {client.ean2 || 'N/A'}</td>
      
      <td style={styles.tableData}>{client.securityStock || 'N/A'}</td>
     
      <td style={styles.tableData}>{client.basePrice || 'N/A'}</td>

      <td style={styles.tableData}>
        {client.isWeighable.isActive===true ? 'Weighable':''}
        {client.isUnitBased.isActive===true ? 'Unit Based':''}
        {client.isPackageBased.isActive===true ? 'Package Based':''}
        {client.isVolumeBased.isActive===true ? 'Volume Based':''}
        {client.isBulk.isActive===true ? 'Bulk':''}
        {client.isServiceBased.isActive===true ? 'Service':''}
        {client.isServiceBased.isActive===false && client.isBulk.isActive===false && client.isVolumeBased.isActive===false && client.isPackageBased.isActive===false && client.isUnitBased.isActive===false && client.isWeighable.isActive===false? 'N/A':''}



      </td>

      <td >
        <button class="button is-rounded fas fa-eye is-small" title='DETAILS' onClick={() => openMOdal({name:client.name,mail:client.info?.email,id:client.id,icon:client.info?.imageIcon,logo:client.info?.imageLogo}, client.info)}><FontAwesomeIcon icon={faEye} size="1x" /></button><button class="button is-rounded fas fa-trash is-small" title='ERASE' onClick={() => openMOdalRm({name:client.name,mail:client.info?.email,id:client.id}, client.info)}><FontAwesomeIcon icon={faTrash} size="1x" /></button></td>

    </tr>
    
  ))}
</tbody>

        </table>
        
      )
      }
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
                </div>
                
              
            
              </div>
             
             
            </div>
          </div>
        )}
      </div>
     

      <div className="card">
        <header className="card-header" style={styles.header} onClick={toggleCollapseCr}>
          <p className="card-header-title"><FontAwesomeIcon icon={faPlus} size="1x" /> Create</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseCr}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedCr ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedCr && (
          <div className="card-content">
           
            <div className="content">
              <div className="media columns is-multiline">
                <div className="box media-content " >
              
      <form onSubmit={handleClientSubmit} class="box" style={styles.form}>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Product Name:</label>
          <input
           class="input  is-small is-rounded"
           type="text"
            value={productName}
            onChange={(e) => setproductName(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Description:</label>
          <input
           class="input  is-small is-rounded"
           type="email"
            value={description}
            onChange={(e) => setdescription(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Sku:</label>
          <input
           class="input  is-small is-rounded"
           type="text"
            value={sku}
            onChange={(e) => setsku(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">EAN1:</label>
          <input
           class="input  is-small is-rounded"
           type="text"
            value={ean1}
            onChange={(e) => setean1(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">EAN2:</label>
          <input
           class="input  is-small is-rounded"
           type="text"
            value={ean2}
            onChange={(e) => setean2(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Image Url:</label>
          <input
           class="input  is-small is-rounded"
           type="text"
            value={imageUrl}
            onChange={(e) => setimageUrl(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Stock:</label>
          <input
           class="input  is-small is-rounded"
           type="number"
           min="0" 
           max="999999999" 
           step="0.0000000001"
            value={stock}
            onChange={(e) => setstock(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Security Stock:</label>
          <input
           class="input  is-small is-rounded"
           type="number"
           min="0" 
           max="999999999" 
           step="0.0000000001"
            value={securityStock}
            onChange={(e) => setsecurityStock(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Base Price:</label>
          <input
           class="input  is-small is-rounded"
           type="number"
           min="0" 
           max="999999999" 
           step="0.0000000001"
            value={basePrice}
            onChange={(e) => setbasePrice(e.target.value)}
          />
        </div>
        <div style={styles.inputGroup}>
          <label class="tag is-normal is-rounded">Weighable:</label>
          <div className='box'>
          <label class="tag is-normal is-rounded">Active:</label>
          <div className='select is-rounded'>
          <select 
         onChange={handleTypeChangeWeighable}  //  // Vinculamos el select al estado 'client'
       >
         {/* Iteramos sobre la lista de clientes y creamos las opciones */}
         <option  value='true'>
           true
           </option>
           <option  value='false'>
           false
           </option>
       </select>
       </div>
          </div>
        </div>
        <label class="tag is-normal is-rounded">Cliente:</label>
     <div className="select is-rounded is-small"> {/* Usamos la clase Bulma correctamente */}
       <select 
         id="selectClients" 
         onChange={handleClientChange}  // Llamamos a la función handleClientChange al cambiar el valor
         value={client}  // Vinculamos el select al estado 'client'
       >
         {/* Iteramos sobre la lista de clientes y creamos las opciones */}
         {clients.map((client, index) => (
           <option key={index} value={client.id}>
             {client.name} {/* El nombre es mostrado como texto */}
           </option>
         ))}
       </select>
     </div>

     {/* Aquí mostramos el nombre del cliente seleccionado */}
     {clientNAME && <p>Cliente Seleccionado: {clientNAME}</p>}
   
        <button type="submit" class="button is-rounded is-small">Crear</button>
      </form>
      
    
     
                </div>
                
              
            
              </div>
             
             
            </div>
          </div>
        )}
      </div>
     

     
       <div>
  
      
    </div>
  
 <Footer
       
       />
    </div> 
    
  );
  
}
const openMOdal= (basicData,data)=>{
  Swal.fire({
    title: "<strong>Información de Cliente</strong>",
    icon: "info",
    html: `
    ${basicData.name}
 <label class="tag">Name</label>
    <input class="input is-rounded" value="${basicData.name.trim()}" id="clientNameInput"></input>
   
     <label class="tag">E-Mail</label>
    <input class="input is-rounded" value="${basicData.mail.trim()}" id="clientMailInput"></input>
     <label class="tag">ICONO</label>
    <input class="input is-rounded" value="${basicData.icon.trim()}" id="clientIconInput"></input>
     <label class="tag">LOGO</label>
    <input class="input is-rounded" value="${basicData.logo.trim()}" id="clientLogoInput"></input>
     
     
    `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: `
      Actualizar
    `,
    cancelButtonText: `Cancelar
    `, preConfirm: async () => {
                               
      async function excexOrder() {
        try {
          const response = await fetch('https://srv687349.hstgr.cloud/waf1/v1/access/put-client', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-APIKEY': sessionStorage.getItem('X-API-KEY'),
              'userId': sessionStorage.getItem('userId'),
              'sessionId': sessionStorage.getItem('sessionId'),
              'X-FRONT': sessionStorage.getItem('frontId'),
              'X-PATH': '/core/clients/put'
            },
            body: JSON.stringify({
              clientName: document.getElementById('clientNameInput').value,
              imageIcon: document.getElementById('clientIconInput').value,
              imageLogo: document.getElementById('clientLogoInput').value,
              clientEmail: document.getElementById('clientMailInput').value,
              clientId:basicData.id
            })
          });
    
          const result = await response.json();
          if(result.validated===false){
            return result;
          }
        //  sessionStorage.clear();
         
          console.log('validado');
               // return  window.location.href = 'Login';
    
      } catch (error) {
          console.error('Error en la ejecución de funciones:', error);
      }
  
      }
   return excexOrder();

}
  });
}

const openMOdalRm= (basicData,data)=>{
  Swal.fire({
    title: "<strong>Se eliminarà informaciòn del cliente</strong>",
    icon: "warning",
    html: `
    `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: `
      Aceptar
    `,
    cancelButtonText: `Cancelar
    `, preConfirm: async () => {
                               
      async function excexOrder() {
        try {
          const response = await fetch('https://srv687349.hstgr.cloud/waf1/v1/access/remove-client', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-APIKEY': sessionStorage.getItem('X-API-KEY'),
              'userId': sessionStorage.getItem('userId'),
              'sessionId': sessionStorage.getItem('sessionId'),
              'X-FRONT': sessionStorage.getItem('frontId'),
              'X-PATH': '/core/clients/remove'
            },
            body: JSON.stringify({
              clientId:basicData.id
            })
          });
    
          const result = await response.json();
          if(result.validated===false){
            return result;
          }
        //  sessionStorage.clear();
         
          console.log('validado');
               // return  window.location.href = 'Login';
    
      } catch (error) {
          console.error('Error en la ejecución de funciones:', error);
      }
  
      }
   return excexOrder();

}
  });
}
const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#f4f7fc',
    color: '#333',
  },
  heading: {
    color: '#3f51b5',
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center',
  },
  form: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontSize: '16px',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  },
  responseMessage: {
    textAlign: 'center',
    color: '#4caf50',
    fontSize: '18px',
    marginTop: '20px',
  },
  table: {
    marginTop: '30px',
    width: '100%',
    borderCollapse: 'collapse',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  tableHeader: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px',
    textAlign: 'left',
  },
  tableData: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  }
};

export default COMSInventory;
