// src/AuthService.js
import { v4 as uuidv4 } from 'uuid';
import Swal from 'sweetalert2'


const  ipProxy ='167.88.45.176';
const  proxy ='PRXLB01';
class AuthService {
  async login(username, password) {
    const newUUID = uuidv4();

   
      const ipResponse = await fetch('https://api.ipify.org?format=json');
      const { ip } = await ipResponse.json();
   
      const userInfoResponse = await fetch(`https://ipinfo.io/${ip}?token=787326c2ee6683`);
      const result2 = await userInfoResponse.json();

      const language = navigator.language || navigator.userLanguage;
      result2.userAgent = navigator.userAgent;
      result2.language = language;
      result2.frontSession = btoa(newUUID);
      result2.ip = result2.ip;
      result2.hostname = result2.hostname || 'CARRIER';
      result2.city = result2.city;
      result2.region = result2.region;
      result2.country = result2.country;
      result2.loc = result2.loc;
      result2.org = result2.org;
      result2.postal = result2.postal;
      result2.timezone = result2.timezone;


      const clientInfo = {
        clientInfo: result2,
      };

      const cryptedData = btoa(JSON.stringify(clientInfo));
      const usName = btoa(username);
      const usPass = btoa(password);

      const response = await fetch('https://srv687349.hstgr.cloud/waflogin/v1/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'X-APIKEY': 'tu_api_key_aqui',
          'Authorization': 'A',
          'X-FRONT': 'A',
          'BASIC-KEY': btoa(btoa(newUUID) + '|' + usName + '|' + btoa(ip) + '|' + btoa(navigator.userAgent) + '|' + btoa(language)),
          'X-PATH': '/core/clients/post',
          'CLIENT-INFO': cryptedData,
          'sessionId': 'A',
          'userId': 'A'
        },
        body: JSON.stringify({
          userName: username,
          pass: usPass,
        }),
      });

      const result = await response.json();
      if(result.validated===false){
        return result;
      }

      const fecha = new Date();
      let horas = fecha.getHours();
      let minutos = fecha.getMinutes();
      let segundos = fecha.getSeconds();
  
      // Asegurarse de que minutos y segundos siempre tengan 2 dígitos
      if (minutos < 10) minutos = '0' + minutos;
      if (segundos < 10) segundos = '0' + segundos;
  
      // Crear la hora de inicio de sesión en formato HH:MM:SS
      const horaActual = `${horas}:${minutos}:${segundos}`;
  
      // Establecer la hora de inicio de sesión en el estado
     // setHoraInicioSesion(horaActual);
  
      // Calcular la hora de expiración (30 minutos después)
      const fechaExpiracion = new Date(fecha.getTime() + 30 * 60000); // Sumar 30 minutos
      let horasExpiracion = fechaExpiracion.getHours();
      let minutosExpiracion = fechaExpiracion.getMinutes();
      let segundosExpiracion = fechaExpiracion.getSeconds();
  
      // Asegurarse de que minutos y segundos siempre tengan 2 dígitos
      if (minutosExpiracion < 10) minutosExpiracion = '0' + minutosExpiracion;
      if (segundosExpiracion < 10) segundosExpiracion = '0' + segundosExpiracion;
  
      // Crear la hora de expiración en formato HH:MM:SS
      const horaExpiracionFormateada = `${horasExpiracion}:${minutosExpiracion}:${segundosExpiracion}`;
  
      // Establecer la hora de expiración en el estado
    //  setHoraExpiracion(horaExpiracionFormateada);
  
      sessionStorage.setItem('X-API-KEY', result.data[0].token);
      sessionStorage.setItem('userName', result.data[0].userName);
      sessionStorage.setItem('name', result.data[0].name);
      sessionStorage.setItem('lastName', result.data[0].lastName);
      sessionStorage.setItem('email', result.data[0].email);
      sessionStorage.setItem('contact', result.data[0].contact);
      sessionStorage.setItem('userId', result.data[0].userId);
      sessionStorage.setItem('sessionId', result.data[0].sessionId);
      sessionStorage.setItem('frontId', result.data[0].frontId);
      sessionStorage.setItem('startTime', horaActual);
      sessionStorage.setItem('endTime', horaExpiracionFormateada);
      sessionStorage.setItem('permissions', JSON.stringify(result.data[0].permissions));
     
      return result;
  
  }

  async logout() {
 

      const response = await fetch('https://srv687349.hstgr.cloud/waflogin/v1/out', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-APIKEY': sessionStorage.getItem('X-API-KEY'),
          'userId': sessionStorage.getItem('userId'),
          'sessionId': sessionStorage.getItem('sessionId')
        },
        body: JSON.stringify({
          sessionId: sessionStorage.getItem('sessionId'),
          userId: sessionStorage.getItem('userId')
        }),
      });

      const result = await response.json();
      if(result.validated===false){
        return result;
      }
      sessionStorage.clear();
      return result;
  
  }


  async clientValidation(qId) {
 
    function getUrlParameter(name) {
      const url = window.location.href;
      const regex = new RegExp("[?&]" + name + "=([^&#]*)", "i");
      const results = regex.exec(url);
      return results ? decodeURIComponent(results[1]) : null;
  }
  const cId = getUrlParameter('id'); // 'Juan'


    const response = await fetch('http://'+ipProxy+'/'+proxy+'/core/auth/validate2F', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': sessionStorage.getItem('X-API-KEY'),
        'userId': sessionStorage.getItem('userId'),
        'sessionId': sessionStorage.getItem('sessionId')
      },
      body: JSON.stringify({
        clientId: cId,
          qId: qId
      }),
    });

    const result = await response.json();
    if(result.validated===false){
      return result;
    }
    sessionStorage.clear();
    return result;

}
async sessionExpired(){
  Swal.fire({
    title: 'SESIÒN CADUCADA',
    allowOutsideClick: false,
    html: `
   

    `,customClass: {
      container: 'custom-container',
      popup: 'custom-popup',
      header: 'custom-header',
      title: 'custom-title',
      closeButton: 'custom-close-button',
      icon: 'custom-icon',
      image: 'custom-image',
      content: 'custom-content',
      input: 'custom-input',
      actions: 'custom-actions',
      confirmButton: 'custom-confirm-button',
      denyButton: 'custom-deny-button',
      cancelButton: 'custom-cancel-button',
     
      footer: 'custom-footer'
  },
  width: '400px',
  padding: '1rem',
  backdrop: `
      rgba(0,0,123,0.4)
  
      left top
      no-repeat
  `,
  position: 'center',
  grow: 'row',
  showConfirmButton: true,
  showCancelButton: false,
  showCloseButton: false,
  cancelButtonText: 'Cancelar',
  allowEscapeKey:false,
    confirmButtonText: 'Salir',
    showClass: {
      popup: `
        animate__animated
        animate__zoomInUp
        
      `
    },
    hideClass: {
      popup: `
        animate__animated
        animate__zoomOutUp
        
      `
    },
    didOpen: () => {
  

   
      
       
  }
}).then((result) => {
    if (result.isConfirmed) {
    
     
        // Aquí puedes manejar los datos del formulario
         async function excexOrder() {
          try {
            const response = await fetch('http://'+ipProxy+'/'+proxy+'/core/auth/postOut', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': sessionStorage.getItem('X-API-KEY'),
                'userId': sessionStorage.getItem('userId'),
                'sessionId': sessionStorage.getItem('sessionId')
              },
              body: JSON.stringify({
                sessionId: sessionStorage.getItem('sessionId'),
                userId: sessionStorage.getItem('userId')
              })
            });
      
            const result = await response.json();
            if(result.validated===false){
              return result;
            }
            sessionStorage.clear();
           
            console.log('validado');
                  return  window.location.href = 'Login';
      
        } catch (error) {
            console.error('Error en la ejecución de funciones:', error);
        }
    
        }
     return excexOrder();
      }else{
       
        
      }
});
}

}

export default new AuthService();
