import React, { useState, useEffect } from 'react';
import AuthService from '../classes/AuthService';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faBox, faBoxes, faBusinessTime, faCalendar, faCalendarCheck, faCalendarDay, faCalendarDays, faCar, faChain, faChartBar, faClose, faCoins, faDiagramNext, faDriversLicense, faExchange, faFile, faFileLines, faGear, faHome, faInbox, faList, faMoneyBill, faNetworkWired, faPeopleArrows, faPerson, faPersonMilitaryPointing, faPlaceOfWorship, faRadio, faRegistered, faServer, faSignOut, faSitemap, faStar, faStore, faUser, faUserGear, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faHomeLgAlt } from '@fortawesome/free-solid-svg-icons/faHomeLgAlt';
import { faHomeUser } from '@fortawesome/free-solid-svg-icons/faHomeUser';
import { faFirstOrder, faServicestack } from '@fortawesome/free-brands-svg-icons';
import { faCashRegister } from '@fortawesome/free-solid-svg-icons/faCashRegister';
import { faMoneyBill1 } from '@fortawesome/free-solid-svg-icons/faMoneyBill1';
const Header = ({ currentPage, totalPages, onPageChange }) => {
  
  const [horaInicioSesion, setHoraInicioSesion] = useState('');
  const [horaExpiracion, setHoraExpiracion] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
 
    // Establecer la hora de inicio de sesión en el estado
    setHoraInicioSesion(sessionStorage.getItem('startTime'));

   
    // Establecer la hora de expiración en el estado
    setHoraExpiracion(sessionStorage.getItem('endTime'));

  }, []); // Solo se ejecuta una vez al montar el componente
  const [isActive, setIsActive] = useState(false);

  // Función para manejar el clic en el botón "navbar burger"
  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const handlePrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };
  const [errorMessage, setErrorMessage] = useState('');
 
  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  const handleLogOut = async (e) => {
  
    e.preventDefault();
   
      try {
        const result = await AuthService.logout();
  
        if (result.validated === true) {
         
  console.log('validado');
          navigate('/Login');
        } else {
          setErrorMessage(result);
        }
      } catch (error) {
        setErrorMessage(error.message);
      }
    }
  
  
   const handleLogClients = async (e) => {
    
    e.preventDefault();
   
      
          navigate('/Clients');
       
      
    }

  
   const handleLogMain = async (e) => {
    
    e.preventDefault();
   
      
          navigate('/MainApp');
       
      
    }

    const handleLogUsers = async (e) => {
     
     e.preventDefault();
    
       
           navigate('/Users');
        
       
     }
     const handleLogUsersClient = async (e) => {
     
      e.preventDefault();
     
        
            navigate('/UsersClient');
         
        
      }
     document.addEventListener('DOMContentLoaded', () => {

      // Get all "navbar-burger" elements
      const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    
      // Add a click event on each of them
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {
    
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);
    
          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
    
        });
      });
    
    });
 
    return (
      <nav className="navbar" role="navigation" aria-label="main navigation"  style={{ backgroundColor: "#4a978e" }}>
        <div className="navbar-brand">
          <a className="navbar-item" onClick={handleLogMain}>
            <img
              viewBox="0 0 640 160"
              fill="none"
              src="https://dev-toolix-admin.lugma.digital/LUGMALOGO.png"
              alt="Logo"
            />
          </a>
  
          {/* Botón para abrir/cerrar el menú */}
          <a
            role="button"
            className={`navbar-burger ${isActive ? 'is-active' : ''}`}
            aria-label="menu"
            aria-expanded={isActive ? 'true' : 'false'}
            onClick={toggleMenu} // Llamamos a toggleMenu al hacer clic
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
  
        {/* Menu desplegable */}
        <div id="navbarBasicExample" className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
          <div className="navbar-start">
            
            <a className="navbar-item"><FontAwesomeIcon icon={faFileLines} size="1x" />Docs</a>
  
           
  
          
             
          </div>
  
          <div className="navbar-end">
            <div className="navbar-item">
              <div className="buttons">
                <button className="button is-light is-small is-rounded" onClick={handleLogOut}>
                <FontAwesomeIcon icon={faSignOut} size="1x" />
                </button>
              </div>
            </div>
  
            <div>
             
             
            </div>
          </div>
        </div>
      </nav>
    );
  };

const styles = {
  paginationContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px 0',
  },
  button: {
    padding: '10px 20px',
    margin: '0 5px',
    backgroundColor: '#007bff',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  pageInfo: {
    fontSize: '16px',
    margin: '0 10px',
  },
};

export default Header;
