import React from "react";
import "./LandingPage.css"; // Archivo CSS para estilos opcionales
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocket, faCogs, faShieldAlt } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
const LandingPage = () => {
      const navigate = useNavigate();

      const handleLoglOGIN = async (e) => {
     
        e.preventDefault();
       
          
              navigate('/Login');
           
          
        }
  return (
    <div className="landing-page">
      {/* Encabezado */}
      <header className="header">
        <nav className="navbar">
          <div className="logo">TuLogo</div>
          <ul className="nav-links">
            <li><a href="#features">Características</a></li>
            <li><a href="#about">Acerca de</a></li>
            <li><a href="#contact" onClick={handleLoglOGIN}>Contacto</a></li>
          </ul>
        </nav>
        <div className="header-content">
          <h1>Bienvenido a Nuestra Plataforma</h1>
          <p>La solución perfecta para tus necesidades digitales.</p>
          <a href="#cta" className="btn-primary">¡Empezar Ahora!</a>
        </div>
      </header>

      {/* Sección de características */}
      <section id="features" className="features">
        <h2>Características Principales</h2>
        <div className="feature-cards">
          <div className="feature-card">
            <FontAwesomeIcon icon={faRocket} size="3x" />
            <h3>Rápido</h3>
            <p>Experimenta un rendimiento excepcional en todas nuestras herramientas.</p>
          </div>
          <div className="feature-card">
            <FontAwesomeIcon icon={faCogs} size="3x" />
            <h3>Fácil de Usar</h3>
            <p>Interfaz intuitiva diseñada para todos los niveles de usuarios.</p>
          </div>
          <div className="feature-card">
            <FontAwesomeIcon icon={faShieldAlt} size="3x" />
            <h3>Seguro</h3>
            <p>Tus datos están protegidos con la última tecnología de seguridad.</p>
          </div>
        </div>
      </section>

      {/* Sección Acerca de */}
      <section id="about" className="about">
        <h2>Acerca de Nosotros</h2>
        <p>
          Somos una empresa dedicada a ofrecer soluciones innovadoras para pequeñas empresas y desarrollos. Nuestro objetivo es simplificar procesos y mejorar la productividad.
        </p>
      </section>

      {/* Llamada a la acción */}
      <section id="cta" className="cta">
        <h2>¡Únete Hoy Mismo!</h2>
        <p>Empieza a aprovechar todos los beneficios que ofrecemos.</p>
        <a href="#contact" className="btn-secondary">Contáctanos</a>
      </section>

      {/* Pie de página */}
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} TuEmpresa. Todos los derechos reservados.</p>
        <p>
          Síguenos en:
          <a href="https://facebook.com" target="_blank" rel="noreferrer"> Facebook</a> |
          <a href="https://twitter.com" target="_blank" rel="noreferrer"> Twitter</a>
        </p>
      </footer>
    </div>
  );
};

export default LandingPage;
