import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthService from '../classes/AuthService';
import Header from './Header';
import Footer from './Footer';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCashRegister } from '@fortawesome/free-solid-svg-icons/faCashRegister';
import { faArrowDownAZ, faBox, faBoxes, faBusinessTime, faCalendar, faCalendarCheck, faCalendarDay, faCalendarDays, faCar, faChain, faChartBar, faClose, faCoins, faDiagramNext, faDriversLicense, faExchange, faFile, faFileLines, faGear, faHome, faInbox, faList, faMoneyBill, faNetworkWired, faPeopleArrows, faPerson, faPersonMilitaryPointing, faPlaceOfWorship, faRadio, faRegistered, faServer, faShop, faSignOut, faSitemap, faStar, faStore, faUpLong, faUser, faUserGear, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faArrowAltCircleDown } from '@fortawesome/free-solid-svg-icons/faArrowAltCircleDown';
import { faDownLong } from '@fortawesome/free-solid-svg-icons/faDownLong';
import { faFirstOrder, faIntercom, faSalesforce } from '@fortawesome/free-brands-svg-icons';
function MainApp() {
  
  const navigate = useNavigate();
 
  useEffect(() => {
    if(!sessionStorage.getItem('userId') || !sessionStorage.getItem('frontId') || !sessionStorage.getItem('sessionId') || !sessionStorage.getItem('X-API-KEY')){
      navigate('/Login');
    }
    console.log('MainApp component is being rendered');
  }, []);

  // Estados para el formulario de creación de cliente
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientKey, setClientKey] = useState('');
  const [clientContact, setClientContact] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
 
  // Estados para la solicitud GET
  const [filter, setFilter] = useState('');
  const [param, setParam] = useState('');
  const [value, setValue] = useState('');
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [clientsData, setClientsData] = useState([]);

  // Función para manejar el envío del formulario de creación de cliente
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isCollapsedClients, setIsCollapsedClients] = useState(true);
  const [isCollapsedCoMS, setIsCollapsedCoMS] = useState(true);
  const [isCollapsedOLMS, setIsCollapsedOLMS] = useState(true);
  const [isCollapsedHRMS, setIsCollapsedHRMS] = useState(true);
  const [isCollapsedSDMS, setIsCollapsedSDMS] = useState(true);

  const [isCollapsedCSMS, setIsCollapsedCSMS] = useState(true);
  // Función para alternar el estado
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  const toggleCollapseClients = () => {
    setIsCollapsedClients(!isCollapsedClients);
  };
  const toggleCollapseCoMS = () => {
    setIsCollapsedCoMS(!isCollapsedCoMS);
  };
  const toggleCollapseOLMS = () => {
    setIsCollapsedOLMS(!isCollapsedOLMS);
  };
  const toggleCollapseHRMS = () => {
    setIsCollapsedHRMS(!isCollapsedHRMS);
  };
  const toggleCollapseSDMS = () => {
    setIsCollapsedSDMS(!isCollapsedSDMS);
  };
  const toggleCollapseCSMS = () => {
    setIsCollapsedCSMS(!isCollapsedCSMS);
  };

  const [isCollapsedPOS, setIsCollapsedPOS] = useState(true);
  const toggleCollapsePOS = () => {
    setIsCollapsedPOS(!isCollapsedPOS);
  };

  const [isCollapsedECM, setIsCollapsedECM] = useState(true);
  const toggleCollapseECM = () => {
    setIsCollapsedECM(!isCollapsedECM);
  };
  const [isCollapsedHYCM, setIsCollapsedHYCM] = useState(true);
  const toggleCollapseHYCM = () => {
    setIsCollapsedHYCM(!isCollapsedHYCM);
  };

  const [isCollapsedRMS, setIsCollapsedRMS] = useState(true);
  const toggleCollapseRMS = () => {
    setIsCollapsedRMS(!isCollapsedRMS);
  };

  const [isCollapsedHoMS, setIsCollapsedHoMS] = useState(true);
  const toggleCollapseHoMS = () => {
    setIsCollapsedHoMS(!isCollapsedHoMS);
  };

  const [isCollapsedAMS, setIsCollapsedAMS] = useState(true);
  const toggleCollapseAMS = () => {
    setIsCollapsedAMS(!isCollapsedAMS);
  };

  const [isCollapsedLMRMS, setIsCollapsedLMRMS] = useState(true);
  const toggleCollapseLMRMS = () => {
    setIsCollapsedLMRMS(!isCollapsedLMRMS);
  };

  const [isCollapsedCCMS, setIsCollapsedCCMS] = useState(true);
  const toggleCollapseCCMS = () => {
    setIsCollapsedCCMS(!isCollapsedCCMS);
  };

  const [isCollapsedIMS, setIsCollapsedIMS] = useState(true);
  const toggleCollapseIMS = () => {
    setIsCollapsedIMS(!isCollapsedIMS);
  };

  const [isCollapsedHWMS, setIsCollapsedHWMS] = useState(true);
  const toggleCollapseHWMS = () => {
    setIsCollapsedHWMS(!isCollapsedHWMS);
  };

  const [isCollapsedTHMS, setIsCollapsedTHMS] = useState(true);
  const toggleCollapseTHMS = () => {
    setIsCollapsedTHMS(!isCollapsedTHMS);
  };


  const [isCollapsedAEMS, setIsCollapsedAEMS] = useState(true);
  const toggleCollapseAEMS = () => {
    setIsCollapsedAEMS(!isCollapsedAEMS);
  };

  const [isCollapsedFRMS, setIsCollapsedFRMS] = useState(true);
  const toggleCollapseFRMS = () => {
    setIsCollapsedFRMS(!isCollapsedFRMS);
  };
 const handleLogOut = async (e) => {
  
  e.preventDefault();
 
    try {
      const result = await AuthService.logout();

      if (result.validated === true) {
       
console.log('validado');
        navigate('/Login');
      } else {
        setErrorMessage(result);
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  }



  // Función para manejar el envío de la solicitud GET
  const handleLogUsers = async (e) => {
 
    e.preventDefault();
   
          navigate('/Users');
       
    }
    const goToCoMSInventory = async (e) => {
 
      e.preventDefault();
     
            navigate('/CoMS/Inventory');
         
      }
      const goToCoMSCatalog = async (e) => {
 
        e.preventDefault();
       
              navigate('/CoMS/Catalog');
           
        }
    const handleLogUsersClient = async (e) => {
     
      e.preventDefault();
     
        
            navigate('/UsersClient');
         
        
      }


   const handleLogClients = async (e) => {
    
    e.preventDefault();
   
      
          navigate('/Clients');
       
      
    }
    const permissions = sessionStorage.getItem("permissions");

// Convertir el string de vuelta a un objeto JSON
const permissionsJson = JSON.parse(permissions);

  return (
    <div className="App" class="box">
       {/* Navbar de Bulma */}
     
       <Header
        currentPage={1}
        totalPages={1}
        onPageChange={1}
      />

      <h1 style={styles.heading}>esta es la pagina de inicio principal donde se muestras dashboards</h1>
      <p className="card-header-title" onClick={toggleCollapse}><FontAwesomeIcon icon={faUsers} size="1x" /> expand all</p>
      <div class="columns is-multiline">


      {permissionsJson.modules.ums.isActive===true ?
      <div className="column is-one-third">
      <div className="card">
        <header className="card-header" style={styles.header} onClick={toggleCollapse}>
          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Users Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapse}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsed ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsed && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">UMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
           
            <div className="content">
              <div className="media columns is-multiline">
              {permissionsJson.modules.ums.submodules.internal===true?
                <div className="box media-content " onClick={handleLogUsers}>
                  <p className="title is-5"  >
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                :''}
                <div className="box media-content " onClick={handleLogUsersClient}>
                  <p className="title is-5" >
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                   <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
            
              </div>
             
            </div>
          </div>
        )}
      </div>
    </div> : ''}
      


    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header} onClick={toggleCollapseClients} >

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Clients Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseClients}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedClients ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedClients && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">ClMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="box media-content" onClick={handleLogClients}>
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faHome} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faNetworkWired} size="1x" /> Integration
                  </p>
                   <p className="subtitle is-7">v1.0.0</p>
                </div>
            
              </div>
            
            </div>
          </div>
        )}
      </div>
    </div>

    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header} onClick={toggleCollapseCoMS}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Comercial Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseCoMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedCoMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedCoMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">CoMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="box media-content" onClick={goToCoMSInventory}>
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faBox} size="1x" />Inventory
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faStore} size="1x" /> Stores
                  </p>
                 <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faList} size="1x" /> Categories
                  </p>
                 <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content" onClick={goToCoMSCatalog}>
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faBoxes} size="1x" /> Catalogs
                  </p>
                   <p className="subtitle is-7">v1.0.0</p>
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faChartBar} size="1x" /> Metrics
                  </p>
                   <p className="subtitle is-7">v1.0.0</p>
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faNetworkWired} size="1x" /> Integrations
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                </div>
                
              </div>
              
             
            </div>
          </div>
        )}
      </div>
    </div>

    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header} onClick={toggleCollapseOLMS}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Operations, Logistics, and Manufacturing System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseOLMS}
          >
            <span className="icon">
            <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedOLMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedOLMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">OLMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
              <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faChain} size="1x" />Chain
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faPlaceOfWorship} size="1x" /> Places
                  </p>
                 <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faSitemap} size="1x" /> Stations
                  </p>
                 <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faMoneyBill} size="1x" /> Bills
                  </p>
                   <p className="subtitle is-7">v1.0.0</p>
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faInbox} size="1x" /> Providers
                  </p>
                   <p className="subtitle is-7">v1.0.0</p>
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faCashRegister} size="1x" /> Debts
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faCoins} size="1x" /> Payments
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faGear} size="1x" /> Machinery
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faCar} size="1x" /> Transportation
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faMoneyBill} size="1x" /> Taxes
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faChartBar} size="1x" /> Metrics
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                </div>
                
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header} onClick={toggleCollapseHRMS}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Human Resources Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseHRMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedHRMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedHRMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">HRMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
              <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faPerson} size="1x" />Employees
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faCalendarCheck} size="1x" />Schedules
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faCoins} size="1x" />Payments
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                
            
              </div>
            </div>
          </div>
        )}
      </div>
    </div>

    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header} onClick={toggleCollapseSDMS}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Sales and Delivery Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseSDMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedSDMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedSDMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">SDMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
              <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faFirstOrder} size="1x" />Orders
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faDriversLicense} size="1x" />Deliveries
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faCashRegister} size="1x" />Boxes
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faExchange} size="1x" />Change
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faPeopleArrows} size="1x" />Customers
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faStar} size="1x" />Fidelization
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faRadio} size="1x" />Localization
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
                <div className="box media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faChartBar} size="1x" />Metrics
                  </p>
                  <p className="subtitle is-7">v1.0.0</p>
                  
                </div>
              </div>
              
            </div>
          </div>
        )}
      </div>
    </div>


    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header} onClick={toggleCollapseCSMS}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Calendar and Scheduling Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseCSMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedCSMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedCSMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">CSMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                   <p className="subtitle is-7">v1.0.0</p>
                </div>
            
              </div>
             
            </div>
          </div>
        )}
      </div>
    </div>

    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header} onClick={toggleCollapsePOS}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Point of Sales Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapsePOS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedPOS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedPOS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">POSMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>


    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> E-Commerce Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseECM}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedECM ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedECM && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">ECMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>

    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Hybrid Commerce Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseHYCM}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedHYCM ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedHYCM && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">HyCMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>


    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Resources Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseRMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedRMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedRMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">RMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>



    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Hospitality Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseHoMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedHoMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedHoMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">HoMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>



    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Academic Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseAMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedAMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedAMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">AMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>

    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Learning Materials and Resources Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseLMRMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedLMRMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedLMRMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">LMRMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>


    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Communication & Collaboration Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseCCMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedCCMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedCCMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">CCMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>

    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Institutional Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseIMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedIMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedIMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">IMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>


    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Health and Wellness Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseHWMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedHWMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedHWMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">HWMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>

    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Travel and Hospitality Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseTHMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedTHMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedTHMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">THMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>


    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Arts and Entertainment Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseAEMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedAEMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedAEMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">AEMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>


    <div className="column is-one-third">
      <div className="card">
      <header className="card-header" style={styles.header}>

          <p className="card-header-title"><FontAwesomeIcon icon={faUsers} size="1x" /> Fashion and Retail Management System</p>
          <button
            className="card-header-icon"
            aria-label="more options"
            onClick={toggleCollapseFRMS}
          >
            <span className="icon">
              <FontAwesomeIcon
                icon={faUpLong}
                size="1x"
                rotation={isCollapsedFRMS ? 180 : 0} // Rota la flecha según el estado
              />
            </span>
          </button>
        </header>
        {/* Contenido colapsable */}
        {!isCollapsedFRMS && (
          <div className="card-content">
            <div className="media">
              <div className="media-left">
                
              </div>
              <div className="media-content">
                <p className="title is-2">FRMS</p>
                <p className="subtitle is-6">Module <p className="subtitle is-6">v1.0.0</p></p>
              </div>
            </div>
            <br />
            
            <div className="content">
              <div className="media columns is-multiline">
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUserGear} size="1x" />Internal
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
                <div className="media-content">
                  <p className="title is-5">
                    <FontAwesomeIcon icon={faUser} size="1x" /> External
                  </p>
                  <p className="subtitle is-7">Submodule <p className="subtitle is-7">v1.0.0</p></p>
                  <p className="subtitle is-7"></p>
                </div>
            
              </div>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Phasellus nec iaculis mauris. <a>@bulmaio</a>.{' '}
              <a href="#">#css</a>
              <a href="#">#responsive</a>
              <br />
              <time datetime="2016-1-1">11:09 PM - 1 Jan 2016</time>
            </div>
          </div>
        )}
      </div>
    </div>

</div>


    
<Footer
       
      />
    </div>
  );
}

const styles = {
  app: {
    fontFamily: 'Arial, sans-serif',
    padding: '20px',
    backgroundColor: '#f4f7fc',
    color: '#333',
  },
  heading: {
    color: '#3f51b5',
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center',
  },
  form: {
    maxWidth: '600px',
    margin: '0 auto',
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  inputGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontSize: '16px',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
  },
  button: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '16px',
    width: '100%',
  },
  responseMessage: {
    textAlign: 'center',
    color: '#4caf50',
    fontSize: '18px',
    marginTop: '20px',
  },
  table: {
    marginTop: '30px',
    width: '100%',
    borderCollapse: 'collapse',
    backgroundColor: '#fff',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  tableHeader: {
    backgroundColor: '#3f51b5',
    color: '#fff',
    padding: '10px',
    textAlign: 'left',
  },
  tableData: {
    padding: '10px',
    borderBottom: '1px solid #ddd',
  },
  header:{
    backgroundColor:'#4a978e'
  }
  
};

export default MainApp;
